@import "../../../core/config/styles/theme.less";

.side-menu-item-active {
  color: #36BC4C !important;
}

.syt-antd-layout-sider-light .syt-antd-layout-sider-trigger {
  display: none;
}

.side-menu-location img{
  height: 20px;
}

.side-menu-user {
  margin-top: 22px;
  margin-bottom: -40px;
  display: flex;
  justify-content: space-between;
}

.side-menu-points {
  color: #848484;
}

.side-menu-username {
  font-weight: 600;
}

.side-menu-notification-number {
  position: relative;
  top: -10px;
  right: 10px;
  padding: 0px 5px;
  border-radius: 50%;
  background-color: #C10000;
  color: white;
}

.side-menu-banner-text {
  width: 183.21px;
  height: 38px;
  left: 6px;
  font-family: "Noto Sans", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #FFFFFF;
  position: relative;
  padding: 20px;
}

.side-menu-user-name {
  font-family: "Noto Sans", sans-serif;
  font-size: 23px;
  font-weight: 800 !important;
  line-height: 31px;
  letter-spacing: 0em;
  text-align: left;
}

.side-menu-banner-li {
  list-style-type: none;
  margin-top: 5px;
  font-weight: 100;
}

.side-menu-banner-image {
  position: absolute;
  margin-left: 120px;
  margin-top: -88px;
}

.side-menu-banner {
  width: 228px;
  height: 124px;
  position: relative;
  left: -10px;
  border-radius: 9px;
  gap: 24px;
  background: #36BC4C;
  margin-top: 10px;
  text-align: center;
  cursor: context-menu;
}

.banner-button-white {
  width: auto;
  height: 35px;
  border-radius: 9.2px;
  color: #36BC4C;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  font-weight: 550 !important;
}

.side-menu-label {
  font-family: "Noto Sans", sans-serif;
  font-size: 13px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0.14em;
  text-align: left;
  width: 77px;
  height: 16px;
  margin-top: 40px;
  cursor: context-menu;
}

.side-menu-text {
  font-family: "Noto Sans", sans-serif;
  font-size: 16px;
  line-height: 21px;
  color: #7A797D;
  letter-spacing: 0em;
  font-weight: 700;
}

.side-menu-items-icon {
  cursor: pointer;
  position: relative;
  width: 24px;
  height: 24px;
  right: 10px;
  color: #7A797D;
}

.side-menu-items {
  position: relative;
  top: 40px;
  left: 8px;
  gap: 16px;
}

.side-submenu-item {
  position: relative;
  margin-top: 15px;
}

.syt-antd-menu-item svg {
  fill: #696f88;
}

.mf-app-sidebar {
  .syt-antd-menu-item:active {
    background: #ffffff;
  }
}

.@{ant-prefix}-layout-sider-trigger{
  background-color: white !important;
}

.@{ant-prefix}-menu-light {
  background: #FFFFFF !important;
}

.@{ant-prefix}-menu-item-selected .sidebar-icon {
  filter: brightness(0) invert(1);
}

.@{ant-prefix}-menu-item {
  padding-left: 24px !important;
}

.@{ant-prefix}-menu-item-selected::before {
  content: " ";
  color: #ffffff;
  position: absolute;
  width: 4px;
  left: 0px;
  top: 4px;
  bottom: 4px;
  background: #ffffff;
  border-radius: 0px 4px 4px 0px;
}

.syt-antd-tooltip-inner {
  display: none;
}

.syngenta-ant-layout-sider, .syngenta-ant-menu.syngenta-ant-menu-dark{
  background-color: white !important;
}

.syt-antd-layout-sider-trigger  {
  color: white !important;
}