@import "../../../shared/main.less";
.banner-title {
  font-family: @font_family_noto;
  font-size: 20px;
  font-weight: 700;
  line-height: 33px;
  letter-spacing: 0em;
  text-align: left;
  color: #232630;
}

.banner-text {
  font-family: @font_family_noto;
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #ffffff;
}

.banner-desc {
  font-family: @font_family_noto;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  text-align: left;
  color: #232630;
}

.card-box {
  width: 100%;
  height: 130px;
  display: flex;
  border-radius: 10px;
  margin-bottom: 20px;
  align-items: center;
  padding: 20px;
}

.banner-with-background-color-img {
  width: 100%;
  height: 130px;
  background-size: cover;
  background-position: center;
  flex-direction: column;
  justify-content: center;
}

.box-with-background {
  justify-content: space-between;
}

.banner-column {
  width: 100%;
}

.banner-button {
  font-family: "Manrope", sans-serif;
  border-radius: 8px;
  font-weight: 500;
  font-size: 12px;
  background-color: @color_green;
}


.banner-btn-align-left {
  justify-content: end;
  align-items: start;
}

.banner-btn-align-right {
  justify-content: end;
  align-items: end; 
}
.banner-btn-title-align {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
@media screen and (max-width: 767px) {
  .banner-column {
    margin-right: 0;
    padding-right: 0;
  }
}
