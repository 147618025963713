@import "./core/config/styles/theme.less";

.sidebarLayout {
  width: 292px;
  height: 1024px;
}
.approot {
  background-color: aquamarine;
}
.navbarLogo {
  margin: 0px 8px 0px 0px !important;
}

.@{ant-prefix}-layout-sider-trigger {
  background-color: @--neutral-neutral-100 !important;
}

.@{ant-prefix}-layout-sider,
.@{ant-prefix}-menu.@{ant-prefix}-menu-dark {
  background-color: @--neutral-neutral-90 !important;
}

.loader {
  border: 4px solid #dfe2e7;
  border-radius: 50%;
  border-top: 4px solid @--green-green-60;
  width: 36px;
  height: 36px;
  -webkit-animation: spin 1.5s linear infinite;
  animation: spin 1.5s linear infinite;
}

.loader-wrapper {
  position: fixed;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  top: 45%;
  z-index: 9999999;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loader-text {
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: @--neutral-neutral-00;
  margin-bottom: 10px;
}

.navbarUserArea {
  padding-right: 0px !important;
  margin-top: 7px !important;
}

.syt-antd-popover-inner-content {
  padding: 5px 0px 20px 0px !important;
}

.syngenta-ant-menu-inline-collapsed {
  width: auto !important;
}

.syt-antd-avatar-circle {
  width: 40px;
  height: 50px;
  line-height: 40px;
  font-size: 18px;
  color: rgb(77, 81, 101);
  display: block;
  cursor: pointer;
  background: rgb(243, 244, 246);
  font-weight: 600;
  text-align: center;
}

.triggerCloseButton {
  margin-top: 13px !important;
  margin-right: 13px !important;
}

.triggerExpandButton {
  margin-top: 13px !important;
}

.navbarDivider {
  display: none;
}

.mf-pl-10 {
  padding-left: 10px;
}

.mf-pt-5 {
  padding-top: 5px;
}

.mf-pt-10 {
  padding-top: 10px;
}

.mf-pb-5 {
  padding-bottom: 5px;
}

.mf-pb-10 {
  padding-bottom: 10px;
}

.mf-p-4 {
  padding: 4px;
}

.mf-p-5 {
  padding: 5px;
}

.mf-p-10 {
  padding: 10px;
}

.mf-p-15 {
  padding: 15px;
}

.mf-pl-0 {
  padding-left: 0px;
}

.mf-pl-5 {
  padding-left: 5px;
}

.mf-mb-10 {
  margin-bottom: 10px;
}

.mf-mb-5 {
  margin-bottom: 5px;
}

.mf-mr-10 {
  margin-right: 10px;
}

.mf-mr-5 {
  margin-right: 5px;
}

.mf-pl-40 {
  padding-left: 40px;
}

.mf-pr-10 {
  padding-right: 10px;
}

.mf-pr-5 {
  padding-right: 5px;
}

.mf-ml-15 {
  margin-left: 15px;
}

.mf-mt-15 {
  margin-top: 15px;
}

.mf-mb-15 {
  margin-bottom: 15px;
}

.mf-m-10 {
  margin: 10px;
}

.mf-text-align-center {
  text-align: center;
}

.mf-font-weight-600 {
  font-weight: 600;
}

.mf-display-none {
  display: none !important;
}

.mf-grid-story-container {
  padding: 35px;
}

.mf-cursor-pointer {
  cursor: pointer;
}

.mf-btn-disabled {
  opacity: 0.6;
  cursor: not-allowed !important;
}

.mf-drawer-content {
  margin-top: 56px;
  height: calc(100% - 50px);
  box-shadow: rgba(0, 0, 0, 0.15) -2px 8px 8px 0px;
  border-left: 1px solid rgb(212, 212, 212);
}

.mf-drawer-title {
  margin: 0;
  color: #14151c;
  font-size: 20px;
  line-height: 28px;
  font-weight: 600;
  font-family: "Noto Sans", sans-serif;
}

.syt-antd-layout {
  width: 100% !important;
}

.mf-width-100 {
  width: 100% !important;
}

.mf-height-100 {
  height: 100% !important;
}

.mf-btn-img {
  background-color: #ffffff;
  border: 0;
  border-radius: 4px;
}

.button-link {
  background: none;
  border: none;
  padding: 0;
  font: inherit;
  text-decoration: underline;
  cursor: pointer;
  color: #14803c;
}
