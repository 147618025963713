.landing-screen-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column;
  margin: 0;
}

.landing-screen-content {
  max-width: 400px;
  text-align: center;
  margin: auto;
}

.syn-field-logo {
  width: 188px;
  height: 70px;
  margin-bottom: 60px;
}

.myfield-welcome-message {
  font-size: 18px;
  font-family: "Noto Sans", sans-serif;
  font-weight: 600;
}

.splash-login-btn {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #14803c;
  font-family: "Noto Sans", sans-serif;
  color: white;
  border: none;
  border-radius: 5px;
  font-weight: 400px;
  cursor: pointer;
  width: 100%;
}

.splash-signup {
  margin-top: 20px;
  font-size: 13px;
}

.splash-signup-link {
  color: #14803c;
}

.syn-field-support {
  margin-top: auto;
  margin-bottom: 20px;
  text-align: center;
}

.syn-support-logo {
  width: 50px;
  height: 50px;
  margin-top: 20px;
}

.syn-loader {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 1;
  width: 40px;
  height: 40px;
  border: 5px solid #ffffff;
  border-radius: 50%;
  border-top: 5px solid #14803c;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}