.mapview {
  width: 100%;
  height: auto;
}

@media only screen {
  @media (max-width: 1400px) {
    .mapview-button {
      width: 45% !important;
    }
  }
  @media (max-width: 1200px) {
    .mapview-button {
      width: 45%;
    }
  }
  @media (max-width: 992px) {
    .mapview-button {
      width: 45% !important;
    }
  }
  @media (max-width: 768px) {
    .mapview-button {
      width: 45% !important;
    }
  }
  @media (max-width: 576px) {
    .mapview-button {
      width: 45% !important;
    }
  }
}

.mapview-container {
  position: relative;
  width: 100%;
  max-width: 100%;
}

.mapview-button {
  width: 37%;
  height: 15%;
  left: 33%;
  border-radius: 8px;
  background: #36bc4c;
  font-family: "Manrope", sans-serif;
  font-size: 17px;
  line-height: 18px;
  letter-spacing: 0em;
  position: absolute;
  top: 60%;
}