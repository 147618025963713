@charset "utf-8";
@font_family_noto: "Noto Sans", "Manrope", sans-serif;
@color_green: #36bc4c;
@color_green_light:  #19A04B;
@color_white: #FFFFFF;
@color_black: #363948;
@color_gray: #4D5165;
@color_red: #CF3537; 
@color_red_dark:  #70191A; 
@color_orange: rgb(225, 179, 27);
@color_border: #dfe2e7;
.stopscrolling {
    overflow-y: hidden;
}