@import "../../shared/main.less";
@media only screen {
 
  @media (min-width: 900px) {
    .arrowimage {
      margin-left: 1px !important;
      margin-right: 1px !important;
      width: 15px;
    }
    .forecastdailyicon {
      width: 25px !important;
    }
    .m-l-28 {
      margin-right: 80px !important;
    }
    .forecastalinment {
      margin-left: 60px !important;
    }
    .weathertemphigh {
      font-size: 150% !important;
    }
    .weathericon {
      width: 20% !important;
      height: 20% !important;
    }
    .weathertemplow {
      font-size: 12px !important;
    }
    .weathertemplowarrows {
      margin-right: 10px !important;
      font-size: 15px !important;
    }

    .forecastdate {
      display: block !important;
      width: auto !important;
      height: 15px !important;
      font-size: 15px !important;
      font-weight: 600;
      color: #696F88;
    }
    .icontemp {
      display: block;
      width: 35px !important;
      height: 20px !important;
    }
  }
}
hr {
  border-top: 2px @color_gray;
}
.weatherReport {
  width: 100%;
  height: auto;
  border-radius: 8px;
  background: @color_white;
  margin-bottom: 5px;
}
.weatherreportselected {
  height: auto;
  border-radius: 8px;
  background: @color_white;
  margin-bottom: 5px;
  width: 100%;
}
.forecastdate,
.forecastweeks {
  text-align: left;
  letter-spacing: -0.0075em;
  font-family: @font_family_noto;
}
.forecastdate {
  width: auto;
  height: 12px;
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
  margin-bottom: 2px;
  color: #696F88;

}

.forecastweeks {
  width: 95px;
  height: 28px;
  font-size: 16px;
  font-weight: 600;
  line-height: 28px;
  color: #363948;

}

.forecastdayinfo {
  width: 95px;
  height: 44px;
  gap: 4px;
}
.forecastrow {
  padding: 15px;
}
.weathericon {
  width: 15%;
  height: 20%;
}
.weathertemphigh {
  font-family: @font_family_noto;
  font-size: 16px;
  font-weight: 600;
  line-height: 44px;
  letter-spacing: -0.0175em;
  text-align: left;
  width: 56px;
  height: 44px;
  color: #363948;
}
.m-r-18 {
  margin-right: 18px !important;
}
.weathertemplowarrows {
  font-weight: 600 !important;
  margin-right: 5px;
}

.weathertemplow {
  font-family: @font_family_noto;
  font-size: 10px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.0075em;
  text-align: left;
  width: 40px;
  height: 24px;
  color: #696f88;
}
.forecasttemp {
  margin-left: 10px;
}
.forecasttodytemp {
  display: flex;
}
.forecastviewmoreinfoTitle {
  font-family: @font_family_noto;
  font-size: 12px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: -0.0075em;
  text-align: left;
  color: #363948;
  margin-bottom: 0px;
}
.forecastviewmoreinfoDesc {
  font-family: @font_family_noto;
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: -0.0075em;
  text-align: left;
  width: 180px;
  height: 12px;
  color: #696f88;
}

.forecastviewmore {
  width: 100%;
  height: auto;
  padding: 16px, 24px, 16px, 24px;
  border-radius: 8px;
  margin-bottom: 5px;
}
.forecastdailycol {
  display: flex;
  justify-content: space-between;
  margin-right: 30px;
  margin-top: 5px;
}
.forecastdailyicon {
  width: 30px;
}
.spancebetween {
  margin-left: 10px;
}
.icontext {
  font-family: @font_family_noto;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: -0.0075em;
  text-align: left;
  white-space: nowrap;
}
.icontemp {
  font-family: @font_family_noto;
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: -0.0075em;
  text-align: left;
  color: @color_gray;
  white-space: nowrap;
}
.arrowimage {
  margin-left: 1px;
  margin-right: 1px;
}
.m-l-28 {
  margin-left: 28px;
}
.degressstyle {
  font-size: 12px !important;
  color: #696f88 !important;
  position: absolute;
}
.forecastviewmoreinfobelow {
  font-family: @font_family_noto;
font-size: 12px;
font-weight: 400;
line-height: 12px;
letter-spacing: -0.0075em;
color: #696F88;

}
